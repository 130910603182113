import React from "react"
import Helmet from "react-helmet"
import Layout from "../../components/layout"
import JumboPhoto from "../../components/jumbophoto"
import ginaPhoto from "../../images/gina-mcdonald.jpg"
import { Container, Row, Col } from "react-bootstrap"

export default () => <Layout>
	<Helmet title="Heather Bellew | Gina Mcdonald &amp; Associates, L.L.C." defer={false} />

	<JumboPhoto/>

	<Container>
		<Row>
			<Col>
				<h1>Gina H. McDonald<br/><small className="text-muted">Managing Partner</small></h1>
				<p>Gina McDonald has a diversified civil practice with particular experience in Consumer bankruptcy and Family law.</p>
                <p>Ms. McDonald has represented clients in courts across central Alabama. Ms. McDonald approaches each case with the goal of gaining the best outcome for her clients in the least stressful manner possible. Ms. McDonald hails from Vicksburg, Mississippi, and holds a B.A. from Northeast Louisiana University (now the University of Louisiana at Monroe), a M.B.A. from the University of Alabama at Birmingham, and her Juris Doctor from the Birmingham School of Law.</p>
                <p>Ms. McDonald is a member of the Alabama State Bar, the Birmingham Bar Association, and the Family Law and Bankruptcy Sections of the Birmingham Bar.</p>
                <p>In addition to her dedication to her clients, Ms. McDonald enjoys spending time with her family, traveling, and appearing as a Motivational Speaker. Ms. McDonald was a contestant on NBC's Biggest Loser, and was the 2013 At Home Winner. She is a frequent guest on local television and radio stations across Alabama, speaking about health, fitness, and legal issues.</p>
			</Col>
			<Col sm="4"><img className="img-fluid mb-3" src={ginaPhoto} alt="Gina McDonald" /></Col>
		</Row>
	</Container>
</Layout>